import React from "react";

const HomeContent = () => {
    return (
        <div className="home-container">
            <div className="banner-area banner-inner-1" id="banner">
                <div className="banner-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 leftpanel">
                                <h2 className="featured">Featured Contest</h2>
                                <div className="thumb after-left-top">
                                    <img src="assets/img/contest/engginno.jpg" alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="banner-details mt-4 mt-lg-0">
                                    <div className="row post-meta-single">
                                        <div className="col-8">
                                            <h2 className="aiml">Engineering Innovation</h2>
                                        </div>
                                        <div className="col-4 mt-3 text-right">
                                            <b style={{ fontSize: "18px", color: "#fff" }}><i class='fas fa-calendar-alt'></i>  Deadline: Nov 30<sup>th</sup> 2024</b>
                                        </div>
                                    </div>
                                    <h2 className="featured-head">Announcing the IIT Madras Shaastra Techathon ‘Engineering Innovation Challenge!’  </h2>
                                    <p>Student teams are tasked with presenting a creative engineering innovation in a topical area. Register your team by November 30<sup>th</sup> 2024, for this online challenge. Top teams may even get the chance to present in person at the IIT Madras campus in Chennai! Challenge details will be revealed on December 4th, 2024. Stay tuned for further updates.<br/> <br /><b>Institutions with five or more participating teams will receive a certificate for 'Fostering an Environment for Technical Innovation'.</b></p>
                                    <a className="btn btn-blue" style={{background: "darkgray", color: "rgb(0, 0, 0)", paddingTop: "11px"}} href="/register">Click here to register</a>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 align-self-center" >
                                <div className="banner-details mt-4 mt-lg-0">
                                    <div className="row post-meta-single">
                                        <div className="col-7" >
                                            <h2 className="aiml">AI/ML CHALLENGE II</h2>
                                        </div>
                                        <div className="col-5  text-right deadlinetxt" style={{ marginTop: "10px" }}>
                                            <b style={{ fontSize: "18px", color: "#ccce2c" }}><i class='fas fa-calendar-alt'></i>  Deadline: July 31<sup>st</sup> 2024</b>
                                        </div>
                                    </div>
                                    <h2 className="featured-head">Join IIT Madras Shaastra Techathon’s ‘AI/ML online challenge - II’. Student teams will use real-world data and AI / ML to make predictions.</h2>
                                    <p className="homepara">Deadline for registering teams is July 31<sup>st</sup> 2024.
                                        In the first week of August (details to be announced to the registered teams), IIT Madras AI/ML club will conduct live online tutorials for participating in the AI/ML challenge.
                                        The challenge details will be announced on the 7<sup>th</sup> of August.
                                        Deadline for submissions will be 12<sup>th</sup> August 2024.
                                        Winners will be announced by 14<sup>st</sup> August 2024.<br /><br /><b>Institutions with 5 or more participating teams will be given a certificate for “Fostering an Active AI/ML Learning Environment”</b></p>
                                    <a className="btn btn-blue regis" style={{ background: "#ccce2c", color: "#5e2e90", paddingTop: "11px", fontWeight: "bold" }} href="/register">Click here to register</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="container" id="contests">
                    <div className="row">
                        {/* <div className="col-lg-4 col-sm-6" style={{ marginBottom: "10px" }}>
                            <a className="tag-base" href="/" style={{ textDecoration: 'none' }}>
                                <div className="single-post-wrap style-white">
                                    <div className="thumb">
                                        <img src="assets/img/contest-home/aiml.jpg" alt="img" />
                                    </div>
                                    <div className="details">
                                        <h6 className="title"><a href="/">Student teams are required to use real-world data and AI / ML to make predictions. IIT Madras AI/ML club will conduct live online tutorials for participating in the AI/ML challengeThis is an online challenge</a></h6>
                                        <div className="post-meta-single mt-3">
                                            <ul>
                                                <li style={{ color: "#ccce2c" }}><i className="fas fa-calendar-alt" ></i> Deadline: July 31<sup>st</sup> 2024</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: "10px" }}>
                            <a className="tag-base" href="/writing" style={{ textDecoration: 'none' }}>
                                <div className="single-post-wrap style-white">
                                    <div className="thumb">
                                        <img src="assets/img/contest-home/technicalwriting.jpg" alt="img" />
                                    </div>
                                    <div className="details">
                                        <h6 className="title"><a href="/writing">Student teams are required to write a technical article based on a topic covered in the archives of Shaastra magazine. This is an online challenge</a></h6>
                                        <div className="post-meta-single mt-3">
                                            <ul>
                                                <li style={{ color: "#ccce2c" }}><i className="fas fa-calendar-alt" ></i> Deadline: July 31<sup>st</sup> 2024</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div> */}

                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: "10px" }}>
                            <a className="tag-base" href="/engg" style={{ textDecoration: 'none' }}>
                                <div className="single-post-wrap style-white">
                                    <div className="thumb">
                                        <img src="assets/img/contest-home/engginno.jpg" alt="img" />
                                    </div>
                                    <div className="details">
                                        <h6 className="title"><a href="/engg">Student teams are required to present a creative engineering innovation based on a topical area. This is an online challenge. Top teams may be invited to present in person.</a></h6>
                                        <div className="post-meta-single mt-3">
                                            <ul>
                                                <li style={{ color: "#ccce2c" }}><i className="fas fa-calendar-alt"></i> Deadline: November 30<sup>th</sup> 2024</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: "10px" }}>
                            <a className="tag-base" href="/" style={{ textDecoration: 'none' }}>
                                <div className="single-post-wrap style-white">
                                    <div className="thumb">
                                        <img src="assets/img/contest-home/sciencequiz.jpg" alt="img" />
                                    </div>
                                    <div className="details">
                                        <h6 className="title">
                                            <a href="/">Student teams are required to use real-world data. This is an online challenge. Details of the Science-quiz will be annouced in September.</a>
                                        </h6>
                                        <div className="post-meta-single mt-3">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        {/* <div className="col-lg-3 col-sm-6" style={{ marginBottom: "10px" }}>
                            <a className="tag-base" href="/art" style={{ textDecoration: 'none' }}>
                                <div className="single-post-wrap style-white">
                                    <div className="thumb">
                                        <img src="assets/img/contest-home/technicalart.jpg" alt="img" />
                                    </div>
                                    <div className="details">
                                        <h6 className="title"><a href="/art">Student teams are required to create technical art based on a topic covered in the archives of Shaastra magazine. This is an online challenge.</a></h6>
                                        <div className="post-meta-single mt-3">
                                            <ul>
                                                <li style={{ color: "#ccce2c" }}><i className="fas fa-calendar-alt"></i> Deadline: November 15<sup>th</sup> 2024</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div> */}


                        <div className="col-lg-4 col-sm-6" style={{ marginBottom: "10px" }}>
                            <a className="tag-base" href="/society" style={{ textDecoration: 'none' }}>
                                <div className="single-post-wrap style-white">
                                    <div className="thumb">
                                        <img src="assets/img/contest-home/innoforsociety.jpg" alt="img" />
                                    </div>
                                    <div className="details">
                                        <h6 className="title"><a href="/society">Student teams are required to present a creative innovation for a topic of societal interest. This is an online challenge. Top teams may be invited to present in person.</a></h6>
                                        <div className="post-meta-single mt-3">
                                            <ul>
                                                <li style={{ color: "#ccce2c" }}><i className="fas fa-calendar-alt"></i> Deadline: December 31<sup>st</sup> 2024</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="back-to-top">
                <span className="back-top"><i class="fa-solid fa-arrow-up" style={{ color: "#FFD43B" }}></i></span>
            </div>
        </div>

    );
};
export default HomeContent;